ion-button {
  font-size: 1.3rem;
  height: 3.6rem;
}
ion-back-button{
  font-size: 1.4rem;
  &::part(icon) {
    font-size: 2.4rem;
  }
}
ion-title{
  font-size: 2rem;
}
ion-item{
  font-size: 1.6rem;
}
ion-icon {
  font-size: 2.4rem;
}