.main-grid {
    min-height: 100%;
    height: -webkit-fill-available;
    width: 100%;

    ion-back-button{
        display: block;
        position: absolute;
        z-index: 1;
        border-radius: 50%;
        top: 10px;
        left: 10px;
        color: var(--ion-color-dark);
        &::part(text){
            display: none;
        }
    }
    ion-row {
    //   min-height: 100%;
      min-height: -webkit-fill-available;
    }
}

  
.box-wrapper{
    padding: 20px 50px;
    max-width: 500px;
    margin: 0 auto;
    border-radius: 25px;
    box-shadow: 0 0 10px rgb(0 0 0 / 13%);
    ion-card-header{
        text-align: center;
        .restaurant-logo{
            // background-color: rgba(var(--ion-color-medium-rgb), 0.3);
            background: linear-gradient(45deg, #bdbdbd, #ebebeb);
            border-radius: 50%;
            width: 200px;
            height: 200px;
            margin: 0 auto 5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            img{
                max-width: 130px;
                margin-bottom: 0;
            }
        }
        img{
            width: 90%;
            height: auto;
            max-width: 250px;
            margin-bottom: 3rem;
        }
        ion-card-title {
            font-size: 26px;
            font-weight: 600;
            display: block;
            margin: 0 0 4px;
        }
        ion-card-subtitle{
            font-size: 20px;
            font-weight: 300;
            text-transform: none;
        }
        p{
            font-size: 1.5rem;
            max-width: 230px;
            text-align: center;
            margin: 0 auto 1rem;
            opacity: .6;
            line-height: 1.2;
            font-weight: 500;
        }
        small{
            font-size: 14px;
            font-weight: 400;
            color: var(--ion-color-dark);
        }
    }

    .button{
        width: 100%;
        margin: 0 auto 2rem;
        height: 45px;
        min-height: 45px;
        text-transform: none;
        &::part(native){
            box-shadow: none;
            border-radius: 10px;
            height: 100%;
        }
        &.btn{
            &::part(native){
                color: var(--ion-color-dark);
            }
        }
    }

    form{
        .item{
            margin-bottom: 2rem;
        }
    }

    .secondary-action{
        text-align: center;
        // padding: 20px 0 10px;
    }
}

@media(max-width:767px){
    .fullH-mob{
        height: 100%;
    }
    .box-wrapper{
        height: 100%;
        padding: 20px 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        box-shadow: none;
        background: transparent;
    }
}