.custom-scrollbar, .custom-scrollbar::part(scroll) {
  overflow: auto;
  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    left: -10px;
    position: relative;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 0;
  }
  
  &:hover{
    &::-webkit-scrollbar-thumb {
      background: rgba(var(--ion-color-dark-rgb), 0.5);
    }
    &::-webkit-scrollbar-track {
      background: rgba(var(--ion-color-dark-rgb), 0.1);
    }
  }

}

@media(max-width:767px){
  .custom-scrollbar {
    -webkit-overflow-scrolling: touch;
  }
  .custom-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .custom-scrollbar::-webkit-scrollbar-thumb,
  .custom-scrollbar::-webkit-scrollbar-track {
    display: none;
  }
  .custom-scrollbar::-webkit-scrollbar {
    position: static;
  }
}