@import "/src/theme/globalVars";
h1,
h2 {
    font-family: $font-inter;
    font-size: 1.8rem;
    font-weight: 600;
    margin: 0;
    color: var(--ion-color-dark);
    small {
        display: block;
        font-size: 0.6em;
        opacity: 0.5;
        line-height: 1.8;
    }
}
h3 {
    font-family: $font-inter;
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--ion-color-dark);
}
h4 {
    // font-size: 2rem;
    // font-weight: 500;
    color: var(--ion-color-dark);
}
h5 {
    // font-size: 1.5rem;
    // font-weight: 500;
    color: var(--ion-color-dark);
}
h6 {
    // font-size: 1.2rem;
    // font-weight: 500;
    color: var(--ion-color-dark);
}
p {
    font-family: $font-family;
    font-size: 1.6rem;
    font-weight: 400;
    opacity: 0.8;
    line-height: 1.4;
    &.sm {
        font-size: 1.4rem;
    }
    color: var(--ion-color-dark);
}

span {
    color: var(--ion-color-dark);
}

// Font Family
.font-source {
    font-family: $font-family;
}
.font-inter {
    font-family: $font-inter;
}

// Font Weights
// Examples:
// .fw-1 (font-weight: 100)
// .fw-9 (font-weight: 900)

@for $i from 1 through 9 {
    .fw-#{$i*1} {
        font-weight: #{$i * 100};
    }
}
