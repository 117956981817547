// Colors
.color {
  &-primary {
    color: var(--ion-color-primary);
  }
  &-secondary {
    color: var(--ion-color-secondary);
  }
  &-tertiary {
    color: var(--ion-color-tertiary);
  }
  &-danger {
    color: var(--ion-color-danger);
  }
  &-success {
    color: var(--ion-color-success);
  }
  &-warning {
    color: var(--ion-color-warning);
  }
  &-dark {
    color: var(--ion-color-dark);
  }
  &-medium {
    color: var(--ion-color-medium);
  }
  &-light {
    color: var(--ion-color-light);
  }
}

// Background
.bg {
  &-primary {
    --background: var(--ion-color-primary);
    background: var(--ion-color-primary);
  }
  &-secondary {
    --background: var(--ion-color-secondary);
    background: var(--ion-color-secondary);
  }
  &-tertiary {
    --background: var(--ion-color-tertiary);
    background: var(--ion-color-tertiary);
  }
  &-danger {
    --background: var(--ion-color-danger);
    background: var(--ion-color-danger);
  }
  &-success {
    --background: var(--ion-color-success);
    background: var(--ion-color-success);
  }
  &-warning {
    --background: var(--ion-color-warning);
    background: var(--ion-color-warning);
  }
  &-dark {
    --background: var(--ion-color-dark);
    background: var(--ion-color-dark);
  }
  &-medium {
    --background: var(--ion-color-medium);
    background: var(--ion-color-medium);
  }
  &-light {
    --background: var(--ion-color-light);
    background: var(--ion-color-light);
  }
}

// Opacity
// Examples:
// .opacity-1 ( opacity: .1)
// .opacity-9 ( opacity: .9)
@for $i from 1 through 9 {
  .opacity-#{$i*1} {
    opacity: #{$i * 0.1};
  }

  .bg {
    &-primary-#{$i*1} {
      --background: rgba(var(--ion-color-primary-rgb), #{$i * 0.1});
      background: rgba(var(--ion-color-primary-rgb), #{$i * 0.1});
    }
    &-secondary-#{$i*1} {
      --background: rgba(var(--ion-color-secondary-rgb), #{$i * 0.1});
      background: rgba(var(--ion-color-secondary-rgb), #{$i * 0.1});
    }
    &-tertiary-#{$i*1} {
      --background: rgba(var(--ion-color-tertiary-rgb), #{$i * 0.1});
      background: rgba(var(--ion-color-tertiary-rgb), #{$i * 0.1});
    }
    &-success-#{$i*1} {
      --background: rgba(var(--ion-color-success-rgb), #{$i * 0.1});
      background: rgba(var(--ion-color-success-rgb), #{$i * 0.1});
    }
    &-warning-#{$i*1} {
      --background: rgba(var(--ion-color-warning-rgb), #{$i * 0.1});
      background: rgba(var(--ion-color-warning-rgb), #{$i * 0.1});
    }
    &-danger-#{$i*1} {
      --background: rgba(var(--ion-color-danger-rgb), #{$i * 0.1});
      background: rgba(var(--ion-color-danger-rgb), #{$i * 0.1});
    }
    &-dark-#{$i*1} {
      --background: rgba(var(--ion-color-dark-rgb), #{$i * 0.1});
      background: rgba(var(--ion-color-dark-rgb), #{$i * 0.1});
    }
    &-medium-#{$i*1} {
      --background: rgba(var(--ion-color-medium-rgb), #{$i * 0.1});
      background: rgba(var(--ion-color-medium-rgb), #{$i * 0.1});
    }
    &-light-#{$i*1} {
      --background: rgba(var(--ion-color-light-rgb), #{$i * 0.1});
      background: rgba(var(--ion-color-light-rgb), #{$i * 0.1});
    }
  }

  .outline-dark-#{$i*1} {
    border-color: rgba(var(--ion-color-dark), #{$i * 0.1});
  }
  .outline-danger-#{$i*1} {
    border-color: rgba(var(--ion-color-danger-rgb), #{$i * 0.1});
  }
  .outline-danger {
    border-color: var(--ion-color-danger);
  }
}
