@import "/src/theme/globalVars";

:root {
  font-family: $font-family;
  font-size: 10px;
  letter-spacing: 1.5px;
}
.primary {
  color: var(--ion-color-primary);
}

// Backgrounds
.bg {
  --background: var(--ion-color-light);
}
.bg-transparent {
  background: transparent !important;
}

// Utilities
.glass-morp {
  backdrop-filter: blur(.8rem);
}

.item-no-left-padding {
  --padding-start: 0;
}
ion-backdrop.sc-ion-modal-md.md.backdrop-no-tappable {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}
ion-modal {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}
.toast {
  --max-width: 35rem;
}
.vHidden {
  visibility: hidden;
}
.modal-close {
  ion-fab-button {
    --box-shadow: none;
    &::part(native) {
      background: transparent;
    }
  }
}

.sc-ion-modal-ios-h {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

.transparent-input {
  --background: rgb(255 255 255 / 10%);
  --border-color: rgb(255 255 255 / 45%);
  margin-bottom: 1rem;
  &.item-has-focus {
    outline: .1rem solid rgb(255 255 255 / 45%);
    padding: 0;
  }
  ion-label {
    color: white !important;
    opacity: 0.8;
  }
  ion-input,
  ion-select {
    --placeholder-color: white;
    --color: white;
    color: white;
  }
  ion-icon {
    color: white;
  }
}

.white-button {
  --background: #fff;
  --color: var(--ion-color-primary);
  --box-shadow: 0 0 .6rem rgb(0 0 0 / 15%);
}
.no-shadow {
  box-shadow: none;
  --box-shadow: none;
}
.pointer {
  cursor: pointer;
}
.radius,
.rounded {
  border-radius: .5rem;
}
.rounded-modal {
  .modal-wrapper {
    border-radius: .8rem;
  }
}

.full-width{
  width: 100%;
}

.fullscreen {
  --width: 100%;
  --height: 100%;
  --border-radius: .8rem;
}

.edit-note-model {
  --width: 31.5rem;
  --height: 20rem;
}

.scrlItem {
  opacity: 0;
  transform: scale(0.5);
  transition: 0.5s ease all;
}

.scrlItem.active-on-scroll {
  opacity: 1;
  transform: scale(1);
}


// ** Status **//
.app-success-status {
  background: rgba(var(--ion-color-success-rgb), 0.1);
  ion-note {
    color: rgba(var(--ion-color-success-rgb), 0.7);
  }
}
.app-warning-status {
  background: rgba(var(--ion-color-warning-rgb), 0.1);
  ion-note {
    color: rgba(var(--ion-color-warning-rgb), 0.7);
  }
}
 
// ** Global Page Level Styles **/

//** Popover Menu Options Starts  **//
.app-menu-options .popover-content {
  max-width: 20rem;
  padding: 0 1rem;
}


.notification-popup{
  &::part(content){
    width: 80%;
    max-width: 35rem;
    // min-width: 50rem;
  }
  .popover-content{
    padding: 0 1rem;
  }
}

.send-notification-popup .modal-wrapper{
  height: auto;
  max-height: 80%;
}
//** Popover Menu Options Ends **//
 
   

 

.flex {
  display: flex;
  align-items: center;
}
.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.flex-column {
  display: flex;
  flex-direction: column;
}
.back-btn {
  margin-right: -1.6rem !important;
}



 
.btn-custom{
  background-color: rgba(var(--ion-color-medium-rgb), 0.3);
  // --background: rgba(var(--ion-color-medium-rgb), 0.3);
  text-transform: none;
  height: unset !important;
  border-radius: .4rem;
  &::part(native){
    padding: .8rem 1rem;
  }
  .flex{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1.4rem;
    small{
      font-weight: 500;
      letter-spacing: .2rem;
      opacity: .7;
      margin-bottom: .3rem;
    }
  }
  .txt{
    display: block;
  }
}



.spin {
  animation: spin-animation 1.5s linear  infinite ;
}

@keyframes spin-animation {
  from {
    transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}




/** Media Queries **/
@media (max-width: 767px) {
  
}

 
