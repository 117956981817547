@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

// Fonts
$font-family: Roboto, "Helvetica Neue", sans-serif;
$font-inter: 'Inter', sans-serif;

// Box Modal
$border-radius: .8rem;
$box-shadow: rgb(0 0 0 / 12%) 0 .4rem 1.6rem;

//Alignment
